export const admin = [
  // {
  //   _name: 'CSidebarNavItem',
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: 'cil-speedometer',
  // },
  {
    _name: 'CSidebarNavTitle',
    _children: ['Menu']
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Kelola Event',
    to: '/event',
    icon: 'cil-puzzle'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Kelola Kategori',
    to: '/category',
    icon: 'cil-puzzle'
  },
  {
    _name: 'CSidebarNavItem',
    name: 'Kelola Pendaftar',
    to: '/registran',
    icon: 'cil-puzzle'
  },
  // {
  //   _name: 'CSidebarNavItem',
  //   name: 'User Transaksi',
  //   to: '/transaction',
  //   icon: 'cil-puzzle'
  // },
  // {
  //   _name: 'CSidebarNavItem',
  //   name: 'User Register',
  //   to: '/user-register',
  //   icon: 'cil-user'
  // },
  // {
  //   _name: 'CSidebarNavDropdown',
  //   name: 'Carrer Interest Survey',
  //   route: '/carrer-interest',
  //   icon: 'cil-file',
  //   items: [
  //     {
  //       name: 'Kelola Group',
  //       to: 'carrer-group',
  //     },
  //     {
  //       name: 'Kelola Pertanyaan',
  //       to: 'carrer-question',
  //     },
  //   ]
  // },
  // {
  //   _name: 'CSidebarNavDropdown',
  //   name: 'Test RMIB Survey',
  //   route: '/rmib',
  //   icon: 'cil-file',
  //   items: [
  //     {
  //       name: 'Kelola Group',
  //       to: 'rmib-group',
  //     },
  //     {
  //       name: 'Kelola Pertanyaan',
  //       to: 'rmib-question',
  //     },
  //   ]
  // },
  // {
  //   _name: 'CSidebarNavDropdown',
  //   name: 'Master',
  //   route: '/master',
  //   icon: 'cil-file',
  //   items: [
  //     {
  //       name: 'Kelola Produk',
  //       to: 'product',
  //     },
  //     {
  //       name: 'Kelola Polres',
  //       to: 'category',
  //     },
  //     {
  //       name: 'Kelola Profil Perusahaan',
  //       to: 'company',
  //     },
  //     {
  //       name: 'Kelola Admin',
  //       to: 'user',
  //     },
  //   ]
  // },
  // {
  //   _name: 'CSidebarNavDropdown',
  //   name: 'Test History',
  //   route: '/history',
  //   icon: 'cil-file',
  //   items: [
  //     {
  //       name: 'Test History Payment',
  //       to: 'history-payment',
  //     },
  //     {
  //       name: 'Test History Result',
  //       to: 'history-result',
  //     }
  //   ]
  // },
  // {
  //   _name: 'CSidebarNavDropdown',
  //   name: 'Test History',
  //   route: '/history',
  //   icon: 'cil-file',
  //   items: [
  //     {
  //       name: 'Test History Payment',
  //       to: 'history-payment',
  //     },
  //     {
  //       name: 'Test History Result',
  //       to: 'history-result',
  //     }
  //   ]
  // },
  // {
  //   _name: 'CSidebarNavDropdown',
  //   name: 'Test Psikologi Lainnya',
  //   route: '/history',
  //   icon: 'cil-file',
  //   items: [
  //     {
  //       name: 'History Payment',
  //       to: 'history-payment',
  //     },
  //     {
  //       name: 'History Result',
  //       to: 'history-result',
  //     },
  //     {
  //       name: 'Kelola Soal',
  //       to: 'test-group',
  //     },
  //     {
  //       name: 'Kelola Paket Soal',
  //       to: 'test-bundle',
  //     }
  //   ]
  // },
]